import * as React from 'react';
import Layout from '../../../components/Layout'
import { graphql } from 'gatsby';
import EventsArchive from '../../../components/EventsArchive';
import SiteHeaderCity2 from '../../../components/SiteHeaderCity2';
export { default as Head } from '../../../components/Head';

const Page = ({ data }) => {
  const events = data?.events?.nodes;
  return (
    <div id="fountain-hills">
      <SiteHeaderCity2/>
      <Layout>
        <div className='page-title main'>
          <div className="columns is-multiline">
            <div className="content-intro column">
              <h1>All<br/><span>Pre-Events</span></h1>
              <div className="line-wrapper">
                <div className="h2underline"></div>
                <div className="marginline"></div>
              </div>
            </div>
            <div className='column'>
              <p>Prior to the outreach event, there will be focused efforts on praying, energizing the church, training Christians to share their faith, raising awareness, and inviting friends.</p>
            </div>
          </div>
        </div>
        <div className="container main noco" id="fountain-events">
          <EventsArchive events={events} />
        </div>
        <div className="columns steps pre-events">
          <div className="column step-img is-half mobile is-hidden">
            <div><img src="https://s3.theark.cloud/static-billygraham-org/sites/lookupcelebration.org/noco/lift-up.jpg" alt="Lift Up" /></div>
          </div>
          <div className="column step-info is-half is-hidden">
            <div className="text">
            <h1>Lift <span>Up</span></h1>
            <p>Prior to the outreach event, there will be focused effort on praying, energizing the church, training Christians to share their faith, raising awareness, and inviting friends.</p>
            <a href="/en/fountain-hills/lift-up" className='button mt-3'>Details</a>
            </div>
          </div>
          <div className="column step-img is-half desktop is-hidden">
            <div><img src="https://s3.theark.cloud/static-billygraham-org/sites/lookupcelebration.org/noco/lift-up.jpg" alt="Lift Up" /></div>
          </div>
          </div>
          <div className="columns steps pre-events">
          <div className="column step-img is-half">
          <div><img src="https://s3.theark.cloud/static-billygraham-org/sites/lookupcelebration.org/noco/clwc.jpg" alt="Christian Life & Witness" /></div>
          </div>
          <div className="column step-info is-half">
            <div className="text">
            <h1><span>Christian Life &<br/>Witness</span> Classes</h1>
            <p>Deepen your faith in Jesus Christ and discover practical ways to live a life that points others to Him.</p>
            <a href="/en/fountain-hills/course" className='button mt-3'>Details</a>
            </div>
          </div>
          </div>
          <div className="columns steps pre-events">
          <div className="column step-img is-half mobile">
          <div><img src="https://s3.theark.cloud/static-billygraham-org/sites/lookupcelebration.org/noco/pursuit.jpg" alt="Pursuit" /></div>
          </div>
          <div className="column step-info is-half">
            <div className="text">
            <h1><span>Pursuit</span> Courses</h1>
            <p>PURSUIT will teach you to learn how to share your faith with your friends. It will be taught in high-energy sessions with time for you to ask questions and interact with other students.</p>
            <a href="/en/fountain-hills/pursuit" className='button mt-3'>Details</a>
            </div>
          </div>
          <div className="column step-img is-half desktop">
            <div><img src="https://s3.theark.cloud/static-billygraham-org/sites/lookupcelebration.org/noco/pursuit.jpg" alt="Pursuit" /></div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export const pageQuery = graphql`
  query {
    events: allMdx(filter: { fields: { slug: { glob: "/en/fountain/events/*" } } } sort: { fields: frontmatter___date }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date
          date2
          date3
          start_time
          category
          venue_name
          subcategory
          slug
          redirectto
        }
        excerpt
      }
    }
  }
`;
export default Page;
