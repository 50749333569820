import { useStaticQuery, graphql } from 'gatsby';

export const useQuery = () => {
const props = useStaticQuery(graphql`
  query MyQuery {
    upcomingEs: allMdx(
      filter: {
        frontmatter: { subcategory: { eq: "upcoming-es" } }
      }
      sort: { fields: frontmatter___date }
    ) {
      nodes {
        frontmatter {
          category
          subcategory
          title
          date
          date2
          date3
          start_time
          venue_name
          slug
          redirectto
        }
        body
      }
    }
    upcomingEs2: allMdx(
      filter: {
        frontmatter: { subcategory: { eq: "upcoming-es-2" } }
      }
      sort: { fields: frontmatter___date }
    ) {
      nodes {
        frontmatter {
          category
          subcategory
          title
          date
          date2
          date3
          start_time
          venue_name
          slug
          redirectto
        }
        body
      }
    }
    upcomingEn: allMdx(
      filter: {
        frontmatter: { subcategory: { eq: "upcoming-en" } }
      }
      sort: { fields: frontmatter___date }
    ) {
      nodes {
        frontmatter {
          category
          subcategory
          title
          date
          date2
          date3
          start_time
          venue_name
          slug
          redirectto
        }
        body
      }
    }
    upcomingEn2: allMdx(
      filter: {
        frontmatter: { subcategory: { eq: "upcoming-en-2" } }
      }
      sort: { fields: frontmatter___date }
    ) {
      nodes {
        frontmatter {
          category
          subcategory
          title
          date
          date2
          date3
          start_time
          venue_name
          slug
          redirectto
        }
        body
      }
    }
  }
`);
return props;
}
