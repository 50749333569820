import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SiteHeader from '../components/SiteHeader';
import SiteHeaderCity from '../components/SiteHeaderCity';
import SiteHeaderCity2 from '../components/SiteHeaderCity2';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import UpcomingEventsEn from '../components/UpcomingEventsEn';
import UpcomingEventsEn2 from '../components/UpcomingEventsEn2';
import UpcomingEventsEs from '../components/UpcomingEventsEs';
import UpcomingEventsEs2 from '../components/UpcomingEventsEs2';
export { default as Head } from '../components/Head';

// import Layout from "../components/Layout";

function Page({ data }) {
  const page = data.page;
  const location = useLocation();

  return (
    <>
      {page.frontmatter.template === 'city-home' ? (
        <>
        <div id={page.frontmatter.city}>
        <SiteHeaderCity />
        <Layout>
          <MDXRenderer>{page.body}</MDXRenderer>
        </Layout>
        </div>
        </>
      ) : page.frontmatter.template === 'city-home-2' ? (
        <>
        <div id={page.frontmatter.city}>
        <SiteHeaderCity2 />
        <Layout>
          <MDXRenderer>{page.body}</MDXRenderer>
        </Layout>
        </div>
        </>
      ) : page.frontmatter.template === 'event' ? (
        <div id={page.frontmatter.city}>
        <SiteHeaderCity />
        <Layout>
          <div class="content section-content event-wrapper main">
            <div class="columns is-multiline">
              <div class="content-intro column">
                <h1><span>{page.frontmatter.title}</span></h1>
                <div className="line-wrapper">
                  <div className="h2underline"></div>
                  <div className="marginline"></div>
                </div>
              </div>
              <div className='column'>
              <div class="block event-copy">
                <div class="content">
                  <MDXRenderer>{page.body}</MDXRenderer>
                </div>
              </div> 
            </div>
          </div>
          </div>
            { location.pathname.split('/')[1] === 'en' ? (
            <div className="container p-5 mb-6 mt-6">
              <div className='page-title pb-3 pre-events'>
                <div className="columns upcoming-title">
                  <div className="left">
                    <h1>More Upcoming<br/><span>Pre-Events</span></h1>
                    <div className="line-wrapper">
                      <div className="h2underline"></div>
                      <div className="marginline"></div>
                    </div>
                  </div>
                </div>
              </div>
              <UpcomingEventsEn />
            </div>
          ) : (
             <div className="container p-5 mb-6 main mt-6">
              <div className='page-title pb-3 pre-events'>
                <div className="columns upcoming-title">
                  <div className="left">
                    <h1>MÁS EVENTOS<br/><span>PREVIOS</span></h1>
                    <div className="line-wrapper">
                      <div className="h2underline"></div>
                      <div className="marginline"></div>
                    </div>
                  </div>
                </div>
              </div>
              <UpcomingEventsEs />
            </div>
          )
        }
        </Layout>
        </div>
        ) : page.frontmatter.template === 'event-2' ? (
          <div id={page.frontmatter.city}>
          <SiteHeaderCity2 />
          <Layout>
            <div class="content section-content event-wrapper main">
              <div class="columns is-multiline">
                <div class="content-intro column">
                  <h1><span>{page.frontmatter.title}</span></h1>
                  <div className="line-wrapper">
                    <div className="h2underline"></div>
                    <div className="marginline"></div>
                  </div>
                </div>
                <div className='column'>
                <div class="block event-copy">
                  <div class="content">
                    <MDXRenderer>{page.body}</MDXRenderer>
                  </div>
                </div> 
              </div>
            </div>
            </div>
              { location.pathname.split('/')[1] === 'en' ? (
              <div className="container p-5 mb-6 mt-6">
                <div className='page-title pb-3 pre-events'>
                  <div className="columns upcoming-title">
                    <div className="left">
                      <h1>More Upcoming<br/><span>Pre-Events</span></h1>
                      <div className="line-wrapper">
                        <div className="h2underline"></div>
                        <div className="marginline"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <UpcomingEventsEn2 />
              </div>
            ) : (
               <div className="container p-5 mb-6 main mt-6">
                <div className='page-title pb-3 pre-events'>
                  <div className="columns upcoming-title">
                    <div className="left">
                      <h1>MÁS EVENTOS<br/><span>PREVIOS</span></h1>
                      <div className="line-wrapper">
                        <div className="h2underline"></div>
                        <div className="marginline"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <UpcomingEventsEs2 />
              </div>
            )
          }
          </Layout>
          </div>
        ) : page.frontmatter.template === 'sub-page' ? (
        <div id={page.frontmatter.city}>
          <SiteHeaderCity />
          <Layout>
            <div className='container content mb-4 copy main'>
              <MDXRenderer>{page.body}</MDXRenderer>
            </div>
          </Layout>
        </div>
        ) : page.frontmatter.template === 'sub-page-2' ? (
          <div id={page.frontmatter.city}>
          <SiteHeaderCity2 />
            <Layout>
              <div className='container content mb-4 copy main'>
                <MDXRenderer>{page.body}</MDXRenderer>
              </div>
            </Layout>
          </div>
        ) : (
        <>
          <SiteHeader />
          <Layout>
            <MDXRenderer>{page.body}</MDXRenderer>
          </Layout>
        </>
    )}
  </>
  );
}

export default Page;

export const pageQuery = graphql`
  query ($id: String) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        template
        title
        slug
        excerpt
        city
        subcategory
        redirectto
      }
      file: parent {
        ... on File {
          modifiedTime
        }
      }
      body
    }
  }
`;

// export const Head = ({
//   data: {
//     mdx: {
//       frontmatter: { title },
//     },
//   },
// }) => {
//   return <SEO title={title} />
// }
